import React from 'react';
import './App.css';
import HomePage from "./components/04-pages/HomePage";
import {BrowserRouter, Route, Routes} from "react-router-dom";

export default function App() {
  return (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage />} />
    </Routes>
  </BrowserRouter>
  );
}
