import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, List, ListItem, ListItemText, Avatar, CircularProgress, Paper, ListItemAvatar, Divider, Grid } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { FaDiscord } from 'react-icons/fa';

interface Player {
    name_clean: string;
    name_html: string;
    name_raw: string;
    uuid: string;
}

interface ServerStatus {
    online: boolean;
    players: {
        max: number;
        online: number;
        list: Player[];
    };
    host: string;
    port: number;
    ip_address: string;
    icon: string;
    retrieved_at: number;
}

export default function HomePage() {
    const [status, setStatus] = useState<string>('Checking...');
    const [serverInfo, setServerInfo] = useState<ServerStatus | null>(null);

    useEffect(() => {
        const fetchServerStatus = async () => {
            try {
                const response = await fetch('https://api.mcstatus.io/v2/status/java/og-riot.net');
                const data = await response.json();
                if (data.online) {
                    setStatus('Online');
                    setServerInfo(data);
                } else {
                    setStatus('Offline');
                }
                console.log(data);
            } catch (error) {
                setStatus('Offline');
                console.error('Error fetching server status:', error);
            }
        };

        fetchServerStatus();
    }, []);

    return (
        <Container maxWidth="md" style={{ textAlign: 'center', marginTop: '2rem' }}>
            <Paper elevation={3} style={{ padding: '2rem' }}>
                <Typography variant="h2" gutterBottom>Welcome to OG Riot</Typography>
                <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                    <Box mr={1}>
                        <Avatar style={{ backgroundColor: status === 'Online' ? green[500] : red[500] }}>
                            {status === 'Checking...' ? <CircularProgress color="inherit" size={24} /> : (status === 'Online' ? '✔' : '✖')}
                        </Avatar>
                    </Box>
                    <Typography variant="h5">Server Status: {status}</Typography>
                </Box>
                {serverInfo && (
                    <Box>
                        <Typography variant="body1" style={{ marginBottom: '1rem' }}>
                            Players: {serverInfo.players.online} / {serverInfo.players.max}
                        </Typography>
                        {serverInfo.players.list.length > 0 && (
                            <Box mt={3}>
                                <Typography variant="h6">Online Players:</Typography>
                                <List>
                                    {serverInfo.players.list.map((player) => (
                                        <React.Fragment key={player.uuid}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        src={`https://crafatar.com/avatars/${player.uuid}?size=40&overlay=true`}
                                                        style={{ borderRadius: 0 }} // Makes the avatar square
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText primary={player.name_clean} secondary={player.name_raw} />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Box>
                        )}
                        <Box mt={3}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<FaDiscord />}
                                        href="https://discord.gg/cat-cave"
                                        target="_blank"
                                    >
                                        Join Discord
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        href="/assets/Regelwerk-og-riot.pdf"
                                        target="_blank"
                                    >
                                        Read Rulebook
                                    </Button>
                                </Grid>
                            </Grid>
                            <Box mt={'1em'}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    href="/assets/allowed-modpack.mrpack"
                                    target="_blank"
                                >
                                    Download allowed Modrinth Modpack
                                </Button>
                            </Box>
                            <Typography variant="body2" style={{ marginTop: '1rem' }}>
                                Please note: The Discord is not only for the OG-Riot.net Minecraft server, but it has a section dedicated to it on the Cat Cave Discord server.
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Paper>
        </Container>
    )
}
